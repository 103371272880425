import React, { Fragment, useEffect, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// services
import { getAllClientes } from '../../../Services/clienteService'

// router
import { withRouter } from 'react-router-dom'

// components
import ItemClienteModalVenta from './ItemClienteModalVenta'

// sweet alert
import Swal from 'sweetalert2'

const ClientesModalVenta = ({
    setCliente,
    setTipoComprobante,
    setListaPrecio,
}) => {
    const [clientes, setClientes] = useState([])
    const [isLoad, setIsLoad] = useState(true)

    // pagination
    const [page, setPage] = useState(0)
    const [totalRegister, setTotalRegister] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (isLoad) {
            getAllClientes(true, 0, query)
                .then((res) => {
                    setClientes(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
            setIsLoad(false)
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }
        if (page === totalPage - 1) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllClientes(true, page + 1, query)
            .then((res) => {
                setPage(page + 1)
                setClientes(res.data.content)
                setTotalRegister(res.data.totalElements)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllClientes(true, page - 1, query)
            .then((res) => {
                setPage(page - 1)
                setClientes(res.data.content)
                setTotalRegister(res.data.totalElements)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const handleBuscador = (e) => {
        setQuery(e.target.value)
        if (e.key === 'Enter') {
            // verificar si la query isEmpty hacer otra consulta
            getAllClientes(true, page + 1, e.target.value)
                .then((res) => {
                    setPage(0)
                    setClientes(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        }
    }

    return (
        <Fragment>
            <button
                type="button"
                className="input-group-text"
                id="inputGroupPrepend"
                data-toggle="modal"
                data-target="#exampleModalScrollableCliente"
            >
                <FontAwesomeIcon icon={faSearch} />
            </button>

            <div
                className="modal fade"
                id="exampleModalScrollableCliente"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalScrollableTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="exampleModalScrollableTitle"
                            >
                                Clientes
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex bd-highlight">
                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <div className="input-group-prepend input-group-lg">
                                        <input
                                            type="text"
                                            className="form-control form-lg"
                                            id="inputNombre"
                                            name="precio"
                                            placeholder="Buscar"
                                            onKeyPress={(e) =>
                                                handleBuscador(e)
                                            }
                                        />
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-row bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button
                                        className="btn btn-outline-info"
                                        onClick={() => previousPage()}
                                        aria-label="Previous"
                                    >
                                        <span aria-hidden="true">
                                            &laquo; Anterior
                                        </span>
                                    </button>
                                </div>
                                <div className="p-2 bd-highlight mt-1">
                                    Registros: {totalRegister} | Pagina actual:{' '}
                                    {page + 1} | Total páginas : {totalPage}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <button
                                        className="btn btn-outline-info"
                                        onClick={() => nextPage()}
                                        aria-label="Next"
                                    >
                                        <span aria-hidden="true">
                                            Siguiente &raquo;
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">DNI</th>
                                        <th scope="col">Condicion Fiscal</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientes.map((cliente, index) => (
                                        <ItemClienteModalVenta
                                            key={parseInt(
                                                cliente.id.persona.id,
                                                10
                                            )}
                                            setTipoComprobante={
                                                setTipoComprobante
                                            }
                                            setListaPrecio={setListaPrecio}
                                            setCliente={setCliente}
                                            cliente={cliente}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ClientesModalVenta)
