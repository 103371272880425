import React from 'react'

// Modal
import ModalDetalleCaja from './ModalDetalleCaja/ModalDetalleCaja'

// router
import { withRouter } from 'react-router-dom'
import { faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'

// services
import { deleteCaja } from '../../Services/cajaService'

// Sweet alert
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ItemCajaReporte = ({ item, setIsLoad, history }) => {
    const estado = (estado) => {
        if (estado === 'ABIERTA') return <p className="text-success">ABIERTA</p>
        else return <p className="text-primary">CERRADA</p>
    }

    const deleteCajaSubmit = (caja) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara una caja!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                deleteCaja(caja.id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'La caja fue eliminada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>{item.puntoVenta.nombre}</td>
            <td>
                {new Date(item.createdAt).toLocaleString('es-AR', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false, // Para formato de 24 horas
                })}
            </td>
            <td>
                {item.fechaCierre
                    ? new Date(item.fechaCierre).toLocaleString('es-AR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false, // Usar formato de 24 horas
                      })
                    : '-'}
            </td>
            <td>${parseFloat(item.montoInicial).toFixed(2)}</td>
            <td>
                $
                {item.montoFinal != null
                    ? `${parseFloat(item.montoFinal).toFixed(2)}`
                    : `${'0.0'}`}
            </td>
            <td>{estado(item.estado)}</td>
            <td>
                {item.estado === 'ABIERTA' ? (
                    <button
                        className="btn text-danger"
                        onClick={() =>
                            history.push('/edit-caja', {
                                puntoVenta: item.puntoVenta,
                            })
                        }
                    >
                        <FontAwesomeIcon icon={faWindowClose} /> Cerrar
                    </button>
                ) : null}
                <ModalDetalleCaja caja={item} />
                <button
                    className="btn text-primary"
                    onClick={() => deleteCajaSubmit(item)}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemCajaReporte)
